import React from 'react'
import styled from 'styled-components'

function ResumeItem({year, title, subTitle, text}) {
    return (
        <ResumeItemMain>
            <div className="left-content">
                <p>{year}</p>
            </div>
            <div className="right-content">
                <h5>{title}</h5>
                <h6>{subTitle}</h6>
                <p>{text}</p>
            </div>
        </ResumeItemMain>
    )
}

const ResumeItemMain = styled.div`
    display: flex;
    @media screen and (max-width: 420px){
        font-size: 30%;
    }
    &::not(::last-child){
        padding-bottom: 3rem;
    }
    .left-content{
        width: 20%;
        padding-left: 20px;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            left: -10px;
            top: 5px;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            border: 2px solid var(--border-color);
            background-color: var(--background-dark-color);
        }
        p{
            display: inline-block;
        }
    }
    .right-content{
        padding-left: 5rem;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 15px;
            height: 2px;
            width: 3rem;
            background-color: var(--border-color);
        }
        h5{
            color: var(--primary-color);
            font-size: 2rem;
            padding-bottom: .4rem;
            @media screen and (max-width:640px){
                font-size: 1.8rem;
            }
        }
        h6{
            padding-bottom: .6rem;
            font-size: 1.5rem;
            @media screen and (max-width:640px){
                font-size: 1.2rem;
            }
        }
        p{
            padding-bottom: 3rem;
        }
    }
`

export default ResumeItem
